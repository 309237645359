@font-face {
  font-family: 'CutiveMono-Regular';
  src: local('CutiveMono-Regular'), url('../public/fonts/CutiveMono-Regular.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'CutiveMono-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code, input, button {
  font-family: 'CutiveMono-Regular', sans-serif;
}

:root {
  --red: #e73027;
  --background: #FCFDFF;
  --black: #000;
  --width-container-desktop: 1920px;
  --height-header: 86px;
  --height-footer: 188px;
  --outline: 1px solid var(--red);
  --width-container-mob: 400px;


}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
}

body {
  background-color: var(--background);

}

li {
  list-style: none;
    margin: 0;
}



a {
  color: var(--black);
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

.swiper-pagination-bullet{
  height: 11px !important; width: 11px !important;
  border-radius: 2px !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--black) !important;

}

